body, html {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    text-rendering: optimizeLegibility;
    text-size-adjust: none;
    cursor: default;
    overscroll-behavior: none;
    -webkit-tap-highlight-color: transparent;
}

* {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    text-rendering: optimizeLegibility;
}

body.dark {
    @apply bg-gray-900;
}

body.light {
    @apply bg-gray-100;
}

.page-header {
    @apply mb-10;
}

#page-title-wormhole .page-title {
    margin-bottom: 0 !important;
}

.page-title {
    @apply text-xl font-semibold text-gray-900;
}

.page-subtitle {
    @apply text-sm;
}

body.dark .page-title {
    @apply text-gray-50;
}

body.dark .page-title.page-title-projects span,
body.datk .page-title.page-title-home span {
    @apply text-white;
}

.post-title {
    @apply w-full border-b border-gray-600 pb-3 mb-10;
}

.post-title > .page-title {
    @apply mb-1;
}

nav.main-nav > a {
    @apply flex border border-transparent rounded-lg px-4 py-1.5 mr-2;
}

body.dark nav.main-nav > a.active,
nav.main-nav > a.active {
    @apply border-green-400 text-green-400;
}

.aboutme p {
    margin-bottom: 1.75rem;
}

.flb-link {
    @apply text-blue-400 underline transition-opacity opacity-100;
}

.fb-link:hover,
.fb-link:visited,
.fb-link:active {
    @apply text-blue-400;
}

.fb-link:hover {
    @apply opacity-50;
}

.hmn-link {
    @apply text-red-500 underline transition-opacity opacity-100;
}

.hmn-link:hover,
.hmn-link:visited,
.hmn-link:active {
    @apply text-red-500;
}

.hmn-link:hover {
    @apply opacity-50;
}

.href-tooltip {
    @apply rounded-md bg-black shadow-sm border border-black px-2 py-1 text-white text-xs flex items-center justify-center truncate;
}

.building-list {
    @apply pl-8;
}

.building-list > li {
    @apply mb-6;
}