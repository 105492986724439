a.btn,
.btn {
	@apply inline-flex items-center px-3 py-2 text-sm font-medium leading-4 transition duration-150 ease-in-out border border-transparent rounded-md;

	&:disabled {
		@apply opacity-50 cursor-not-allowed;
	}

	&.btn-xs {
		@apply px-2.5 py-1.5 text-sm leading-4 font-medium rounded-md;
	}

	&.btn-sm {
		@apply px-3 py-2 text-sm font-medium leading-4 rounded-md;
	}

	&.btn-md {
		@apply px-4 py-2 text-sm font-medium leading-5 rounded-md;
	}

	&.btn-lg {
		@apply px-4 py-2 text-base font-medium leading-6 rounded-md;
	}

	&.btn-xl {
		@apply px-6 py-3 text-base font-medium leading-6 rounded-md;
	}

	&.btn-link {
		@apply border-0 outline-none shadow-none px-0 py-0;
	}

	&.btn-default {
		@apply bg-white border-gray-300 shadow-sm;
		&:hover {
			@apply text-gray-500;
		}
		&:focus {
			@apply border-gray-300 outline-none;
		}
		&:active {
			@apply text-gray-800 bg-gray-50;
		}
	}

	&.fb-button-primary,
	&.btn-primary {
		@apply text-white bg-blue-500 border-blue-500 shadow;
		&:hover:not(:disabled) {
			@apply bg-sky-400;
		}
		&:focus {
			@apply outline-none border-blue-600;
		}
		&:active {
			@apply bg-blue-600;
		}
	}

	&.fb-button-success,
	&.btn-success {
		@apply text-white bg-green-500 border-green-600 shadow-sm;
		&:hover:not(:disabled) {
			@apply bg-green-400;
		}
		&:focus {
			@apply outline-none border-green-600;
		}
		&:active {
			@apply bg-green-600;
		}
	}

	&.btn-magic {
		@apply text-white bg-indigo-600 border-indigo-700 shadow-sm;
		&:hover:not(:disabled) {
			@apply bg-indigo-500 text-white;
		}
		&:focus {
			@apply outline-none border-indigo-700;
		}
		&:active {
			@apply bg-indigo-700;
		}
	}

	&.btn-secondary {
		@apply text-blue-500 bg-blue-100 border-blue-300 shadow-sm;
		&:hover:not(:disabled) {
			@apply bg-blue-50;
		}
		&:focus {
			@apply border-blue-300 outline-none;
		}
		&:active {
			@apply bg-blue-200;
		}
	}

	&.btn-danger {
		@apply text-white bg-red-600 border-red-700 shadow-sm;
		&:hover:not(:disabled) {
			@apply bg-red-500;
		}
		&:focus {
			@apply border-red-700 outline-none;
		}
		&:active {
			@apply bg-red-700;
		}
	}

	&.btn-warning {
		@apply text-white bg-yellow-500 border-yellow-600 shadow-sm;
		&:hover:not(:disabled) {
			@apply bg-yellow-400;
		}
		&:focus {
			@apply border-yellow-600 outline-none;
		}
		&:active {
			@apply bg-yellow-600;
		}
	}
}

body.dark a.btn,
body.dark .btn {
	display: inline-flex;

	&.btn-default {
		@apply text-gray-300 bg-gray-800 border-gray-900 shadow;
		&:hover:not(:disabled) {
			@apply text-gray-200 bg-gray-600;
		}
		&:focus {
			@apply outline-none;
		}
		&:active {
			@apply text-gray-300 bg-gray-600;
		}
	}

	&.btn-link {
		@apply text-gray-100;

		&.dd-is-open,
		&:hover,
		&:active {
			@apply text-gray-50 text-opacity-50;
		}
	}

	&.btn-primary {
		@apply border-gray-900 shadow;
	}

	&.btn-magic {
		@apply border-gray-900 shadow;
	}

	&.btn-secondary {
		@apply border-gray-900 shadow;
	}

	&.btn-warning {
		@apply border-gray-900 shadow;
	}

	&.btn-danger {
		@apply border-gray-900 shadow bg-red-700;
		&:hover:not(:disabled) {
			@apply bg-red-600;
		}
	}
}

body.dark .btn.btn-default.btn-android svg,
body.dark .btn.btn-android svg,
.btn.btn-default.btn-android svg,
.btn.btn-android svg {
    @apply text-green-500;
}